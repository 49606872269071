.canvas {
    height: 60px;
    border-radius: $radius-button;

    &__block {
        width: 450px;
        height: 338px;
        border-radius: $radius-block;
        box-sizing: border-box;
        box-shadow: 0 0 6px $color-gray;
        overflow: hidden;
    }
    &__feed {
        width: inherit;
    }
    &__hidde {
        display: none;
    }
}