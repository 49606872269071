.symbol {
    &__input {
        /* dummy */
    }

    &__board {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 8px;
    }

    &__key {
        @mixin gradient-darken bottom, $color-white, 3%;

        min-width: 24px;
        height: 24px;
        margin: 0 8px 8px 0;
        padding: 0 8px;
        border: 1px solid $color-gold;
        text-align: center;
        text-decoration: none;
        color: $color-black;
        box-shadow: 2px 2px 4px rgba(196, 196, 196, 0.3);
        border-radius: 6px 0;

        &:hover {
            box-shadow: 0 0 4px 0 $color-gray;
        }

        &:active {
            box-shadow: inset 0 0 4px 0 $color-gray;
        }

        &.is-disabled {
            color: $color-lightgray;
            box-shadow: none;
            outline: none;
            cursor: default;
        }
    }

    &__sign {
        vertical-align: middle;
        font-size: 16px;
        line-height: 24px;
    }

    &__name {
        vertical-align: middle;
        margin-left: 4px;
        font-size: 14px;
        line-height: 24px;
    }
}
