.nav_elements {
    &__cross {
        height: 25px;
        width: 25px;
        color: $color-white;
        background-color: $color-fianit;
        border: 1px solid $color-fianit;
        border-radius: 50%;
        position: absolute;
        right: -8px;
        top: -8px;
        cursor: pointer;

        &::before {
            position: relative;
            content: "\d7";
            font-size: 19px;

            font-weight: bold;
            top: -1px;
            left: 4px;
        }
        &:hover {
            color: $color-fianit;
            background-color: $color-white;
        }
    }
}
