.form {
    margin-bottom: 20px;

    &.modal-form {
        margin-bottom: 0;
    }

    &__field {
        & + & {
            margin-top: 16px;
        }
    }

    &__fieldset {
        border: 1px solid $color-fianit;
        border-radius: 10px 0px;
        padding: 8px 24px;
        & + & {
            margin-top: 16px;
        }
    }

    &__message {
        margin-top: 16px;
        padding: 4px 16px;
        font-size: 16px;
        line-height: 1.5;
        color: $color-lightblack;
        background-color: rgba($color-lightblack, 0.02);

        &--error {
            color: $color-lightred;
            background-color: rgba($color-lightred, 0.03);
        }

        &--warning {
            background-color: rgba($color-yellow, 0.03);
        }

        &--info {
            background-color: rgba($color-blue, 0.03);
        }
    }

    &__remark {
        margin-top: 24px;
        color: $color-darkgray;
        font-size: 16px;
        line-height: 1.5;
    }

    &__panel {
        margin-top: 24px;
    }

    &__verify {
        width: 79px;
        margin: auto;
    }
}
