.start {
    position: relative;
    height: 100%;
    background-image: linear-gradient(to bottom, $color-red 0, color($color-red shade(3%)) 50%, $color-white 50%, $color-white 100%);

    &__logo {
        position: absolute;
        left: 50%;
        top: 7%;
        width: 288px;
        height: 22px;
        margin: -11px 0 0 -144px;
        background: svg-load('assets/img/logo.svg', fill=$color-white) 50% 50%/contain no-repeat;
    }

    &__window {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 432px;
        margin: -240px 0 0 -216px;
        background-color: $color-white;
        border: 1px solid $color-lightgray;
        border-radius: 4px;
        box-shadow: 0 0 16px 0 rgba($color-lightblack, 0.1);
    }

    &__block {
        padding: 32px;
    }

    &__loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color-white, 0.85);
        cursor: wait;

        &-spinner {
            position: absolute;
            left: 50%;
            top: 104px;
            width: 144px;
            height: 144px;
            margin-left: -72px;
        }

        &-label {
            position: absolute;
            left: 0;
            top: 280px;
            width: 100%;
            color: $color-lightblack;
            font-size: 26px;
            text-align: center;
        }
    }

    &__header {
        /* dummy */
    }

    &__form {
        /* dummy */
    }

    &__submit {
        width: 100%;
    }

    &__recover {
        border-top: 1px solid $color-lightgray;
        padding: 18px 32px;
        font-size: 14px;
        line-height: 1.33;
        text-align: center;
    }
}
