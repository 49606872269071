.hello {
    &__block {
        @mixin aside-grid;
        @mixin aside-margin;

        border: 1px solid $color-lightgray;
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba($color-lightgray, 0.75);
    }

    &__icon {
        grid-column: 1 / 2;
        background: svg-load('assets/img/icon/person.svg', fill=$color-red) 85% 50% no-repeat;
    }

    &__person {
        grid-column: 2 / 5;
    }

    &__name {
        font-size: 18px;
        line-height: 1.5;
        font-weight: semibold;
    }

    &__comment {
        font-size: 14px;
        line-height: 1.5;
    }
}
