.main {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-top: 100px;
    padding-bottom: 24px;
    background-color: $color-darkwhite;

    &__head {
        flex-grow: 0;
        padding-bottom: 16px;
    }

    &__submit {
        margin-left: 16px;
    }

    &__body {
        display: grid;
        grid-template-areas:
            "canvas screen"
            "bottom bottom";
        grid-template-columns: 450px 410px;
        grid-template-rows: 1fr auto;
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        &.search {
            display: flex;
            justify-content: space-between;
            flex-grow: 0;
            flex-wrap: wrap;
        }
    }

    &__foot {
        padding-top: 32px;
        flex-grow: 0;
    }

    &__block {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;
    }

    &__section {
        & + & {
            margin-top: 32px;
        }
    }

    &__goto {
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin-left: 16px;
        background: svg-load('assets/img/icon/edit.svg', fill=$color-lightblack) 50% 50% no-repeat;
    }
}
