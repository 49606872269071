.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity .3s ease;

    &__btn {
        min-width: 150px;
    }

    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-lightblack, 0.35);
    }

    &__container {
        $modal-height: 480px;
        
        position: absolute;
        left: 50%;
        top: 50%;
        width: $modal-width;
        min-height: $modal-height;
        height: 100%;
        max-height: 521px;
        margin: calculate(-$modal-height/2) 0 0 calculate(-$modal-width/2);
        border-radius: 10px 0px;
        box-shadow: 0 0 4px 0 rgba($color-lightblack, 0.25);
        background-color: $color-white;
        overflow: auto;
        transition: all .3s ease;
    }

    &__block {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
        padding-bottom: 16px;
        border-radius: 10px 0px;
    }

    &__head {
        border: 1px solid #E5BD79;
        background-color: $color-gold;
        color: $color-darkwhite;
        padding: 16px 32px 8px;
        border-radius: 10px 0px 0px 0px;
        flex-shrink: 0;
        font-size: 18px;
    }

    &__body {
        padding: 16px 32px;
        flex-grow: 1;
    }

    &__foot {
        flex-shrink: 0;
        padding: 8px 32px;
    }

    &__date {
        padding: 8px 32px;
        font-size: 12px;
        line-height: 1.2;
    }

    &__panel {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__prev {
        grid-column: 1/5;
    }

    &__next {
        grid-column: 5/9;
    }

    &__submit {
        grid-column: 1/9;
    }

    /* Vue.js transition */
    &.modal-enter-active,
    &.modal-leave-active {
        transition: all .5s;
    }

    &.modal-enter,
    &.modal-leave-active {
        transition: opacity .5s;
        opacity: 0;
    }

    &.modal-enter &__container,
    &.modal-leave-active &__container {
        transform: scale(1.1);
    }

    &.modal-verify &__container {
        width: 390px;
        min-height: 204px;
        max-height: 204px;
        margin: calculate(-390px / 2) 0 0 calculate(-204px / 2);
    }

    &.modal-verify &__date {
        display: none;
    }
    &.modal-verify &__panel {
        justify-content: center;
    }

    &.modal-builder &__container {
        width: 675px;
        min-height: 204px;
        max-height: 100%;
        height: auto;
        margin: calculate(-835px / 2) 0 0 calculate(-675px / 2);
    }

    &.modal-builder &__date {
        display: none;
    }
}
