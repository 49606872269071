.field {
    &--inline {
        @mixin main-grid;
        align-items: center;
        &.modal-form__field {
            display: flex;
            justify-content: space-between;
        }
    }

    &--type_hidden {
        display: none;
    }

    &__label {
        display: block;
        margin: 0 0 8px 8px;
        color: $color-lightblack;
        font-size: 16px;
        font-weight: medium;
    }

    &--required &__label::after {
        content: '*';
        margin-left: 4px;
        color: $color-red;
    }

    &--inline &__label {
        grid-column: 1/4;
        align-self: center;
        margin: 0;
    }

    .modal-form__field &__label {
        width: 197px;
    }

    &--inline&--type_boolean &__label {
        display: none;
    }

    &__input {
        &.is-verified, &.is-unverified {
            position: relative;
            
            &:after {
                content: '';
                width: 21px;
                height: 21px;
                background-size: contain;
                position: absolute;
                background-repeat: no-repeat;
                left: calc(100% + 8px);
                top: calc(50% - 10.5px);
            }
        }
        &.is-verified {
            &:after {
                background-image: svg-load('assets/img/icon/verified.svg');
            }
        }
        &.is-unverified {
            &:after {
                background-image: svg-load('assets/img/icon/unverified.svg');
            }
        }
    }

    .modal-form__field &__input {
        width: 397px;
    }

    &__comment {
        position: relative;
    }

    .modal-form__field &__comment {
        display: none;
    }

    &--type_document &__input {
        display: flex;
        justify-content: space-between;

        input {
            width: 49%;
        }
    }

    &--type_person &__input {
        display: flex;
        justify-content: space-between;

        input {
            width: 32%;
        }
    }

    /* &--type_house &__input {
            display: flex;display
            justify-content: space-between;justify-content

            select,
        input {
                    width: 49%;width
            }
    } */

    /* &--type_ticket &__input {
        display: flex;
        justify-content: space-between;

        input {
            width: 48%;
        }
    } */

    &--type_verify &__input {
        display: flex;
        justify-content: space-between;

        input {
            width: 24%;
        }

        button {
            width: 34%;
        }
    }

    &--type_username &__input {
        input {
            @mixin input--icon passport;
        }
    }

    &--type_password &__input {
        input {
            @mixin input--icon lock;
        }
    }

    &--inline &__input {
        grid-column: 4/9;
    }

    &--inline&--type_boolean &__input {
        grid-column: 1/11;
    }

    &--inline &__comment {
        margin-left: -8px;
        max-height: 37px;
        line-height: 1;
        grid-column: 9/11;
    }

    &--inline &__action {
        grid-column: 11/14;
    }
}
