<template>
    <header class="app__header header container">
        <div class="header__logo" />

        <div class="header__button">
            <slot>
                <button
                    class="button button--darkyellow main__cancel"
                >
                    Продолжить заполнение
                </button>
            </slot>
        </div>
    </header>
</template>

<style scoped lang="postcss">
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #720F13;
        color: #ffffff;
        position: fixed;
        top: 0;
        z-index: 2;
        padding-top: 12px;
        padding-bottom: 12px;

        &__logo {
            height: 50px;
            width: 50px;
            background: svg-load('assets/img/logo2.svg') no-repeat;
            background-size: 50px 50px;
        }

        &__button {
            padding-left: 16px;
        }
    }
</style>