.value {
    &--inline {
        @mixin main-grid;
    }

    &__label {
        display: block;
        margin: 0 0 8px 8px;
        color: $color-lightblack;
        font-size: 18px;
        font-weight: medium;
    }

    &--inline &__label {
        grid-column: 1/5;
        align-self: flex-start;
        margin: 0;
    }

    &__value {
        font-size: 18px;
        font-weight: semibold;
    }

    &--inline &__value {
        grid-column: 5/11;
    }

    &--empty &__value {
        font-weight: light;
    }

    &__comment {
        position: relative;
    }

    &--inline &__comment {
        grid-column: 11/13;
    }

    &__tooltip {
        /* dummy */
    }
}
