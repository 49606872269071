@keyframes spinner {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, $color-white 0, $color-lightgray 100%);
    animation-duration: 1s;
    animation-delay: 0;
    animation-timing-function: linear;
    animation-name: spinner;
    animation-iteration-count: infinite;

    &::after {
        content: '';
        position: absolute;
        left: 20%;
        top: 20%;
        width: 60%;
        height: 60%;
        border-radius: 50%;
        background-color: $color-white;
    }
    &--sizex25 {
        width: 25px;
        height: 25px;
    }
    &--color-lightblack {
        background-image: linear-gradient(to bottom, $color-white 0, $color-lightblack 100%);
    }
    &__screen {
        position: absolute;
        right: -8px;
        top: -8px;
    }
}
