.screen {
    position: relative;
    border-radius: $radius-button;
    display: inline-block;
    margin: 10px 8px 5px;

    & canvas {
        border: 2px solid $color-gray;
        box-shadow: 0 0 0 4px $color-gray;
    }
}