.gallery {
    &__block {
        min-height: 50px;
        width: 800px;
        padding: 13px;
        border: 1px solid $color-fianit;
        border-radius: $radius-block;
        box-sizing: border-box;
        box-shadow: 0 0 6px $color-gray;
    }
    &__button {
        margin: 10px 0;
        min-width: 200px;
        display: inline-block;
        padding: 16px 24px;
    }
    &__comment {
        line-height: 1;
        width: 185px;
        display: inline-block;
        margin-left: 10px;

    }
    &__vertical_block {
        height: 340px;
        width: 410px;
        overflow-y: scroll;
    }
}