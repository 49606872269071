.app {
    position: relative;
    min-height: 100vh;
    width: 100%;
    max-width: 960px;
    margin: auto;
    height: 100%;
    background-color: $color-darkwhite;

    &__block {
        display: flex;
        flex-direction: column;

        position: relative;
    }

    &__header {
        position: relative;
    }

    &__main {
        position: relative;
        min-height: 100%;
    }
}
