.aside {
    &.is-active {
        z-index: 10;
    }

    &__bg {
        position: fixed;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-black, 0.45);

        &.bg-enter-active,
        &.bg-leave-active {
            transition: opacity .5s;
        }

        &.bg-enter,
        &.bg-leave-active {
            opacity: 0;
        }
    }

    &__block {
        position: relative;
        z-index: 20;
        height: 100%;
        border-right: 1px solid $color-lightgray;
        background-color: $color-white;
    }

    &__logo {
        @mixin aside-grid;

        &::before {
            content: '';
            display: block;
            height: 80px;
            grid-column: 1/5;
            background: svg-load('assets/img/logo.svg', fill=$color-red) 50% 50%/contain no-repeat;
        }

        border-bottom: 1px solid $color-lightgray;
    }

    &__hello {
        margin-top: 32px;
    }

    &__nav {
        margin-top: 40px;
    }

    &__chat {
        position: absolute;
        left: 100%;
        top: 0;
        width: 456px;
        height: 100%;
    }
}
