.panel {
    border-radius: 10px 0;
    width: 100%;

    &__head {
        @mixin gradient-darken bottom, $color-fianit, 3%;

        position: relative;
        display: block;
        padding: 8px 24px;
        box-shadow: 2px 2px 8px #C4C4C4;
        border-radius: 10px 0 0 0;
        text-decoration: none;
        color: $color-lightblack;

        &::after {
            display: none;
            content: '';
            position: absolute;
            right: 24px;
            top: 50%;
            width: 24px;
            height: 24px;
            margin-top: -12px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }

        .h2 {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: $color-white;
        }
    }

    &__body {
        display: block;
        padding: 16px 24px 12px;
        border: solid $color-fianit;
        border-width: 0 1px 1px;
        border-radius: 0 0 10px 0;
        background-color: $color-white;
    }

    &.is-closed &__head::after {
        display: block;
        background-image: svg-load('assets/img/icon/plus.svg', fill=$color-green);
    }

    &.is-closed &__body {
        display: none;
    }

    &.is-opened &__head::after {
        display: block;
        background-image: svg-load('assets/img/icon/minus.svg', fill=$color-green);
    }

    &.is-opened &__body {
        display: block;
    }

    &.is-half {
        width: 48%;
    }
}
